import "./css/Blog.css";
import Section from "../Components/Section";
import { useState } from "react";
import getArticles from "../functions/getArticles";
import CategorySelector from "../Components/CategorySelector";
import { Link } from "react-router-dom";
import { Article } from "../global";

export type ThumbnailProps = {
    article: Article
    includeTags?: boolean
}

function Thumbnail(props: ThumbnailProps) {
    return (
        <Link to={`/article/${props.article.id}`} className="thumbnail">
            <img src={props.article.banner} alt="thumbnail" className="tn-banner" />
            <div className="tn-title">
                {props.includeTags && props.article.tags.length >= 1 ?
                    <div className="gip-tag">{props.article.tags[0]}</div>
                    : ""
                }

                <div className="tn-title-text">
                    {props.article.title}
                </div>
            </div>
        </Link>
    )
}

type PageSelectorProps = {
    currentPage: number;
    totalPages: number;
    maxButtons?: number;
    onPageChange: (page: number) => void;
}

function PageSelector(props: PageSelectorProps) {
    const selectors = [];
    for (let page = 1; page <= props.totalPages; page++) {
        selectors.push(
            <button
                key={page}
                className={props.currentPage === page ?
                    "page-selector selected" :
                    "page-selector"}
                onClick={() => props.onPageChange(page)}
            >
                {page.toString().padStart(2, "0")}
            </button>
        )
    }
    return (
        <div className="page-selector-container">
            {selectors}
        </div>
    )
}

export default function Blog() {
    const [articlesPerPage, setArticlesPerPage] = useState(8);
    const [currentPage, setCurrentPage] = useState(1);
    const articles = getArticles();

    return (
        <Section title="Blog" className="blog-section">
            <div className="blog">
                <CategorySelector />

                <div className="blog-articles">
                    <div className="blog-articles-grid">
                        {
                            articles.map((article, index) => {
                                if (index >= (currentPage - 1) * articlesPerPage && index < currentPage * articlesPerPage)
                                    return <Thumbnail key={index} includeTags={true} article={article} />
                                else
                                    return null
                            })
                        }
                    </div>
                </div>
                <PageSelector currentPage={currentPage} totalPages={Math.ceil(articles.length / articlesPerPage)} onPageChange={setCurrentPage} />
            </div>
        </Section>
    )
}
