import banner from "../images/bg-minters.webp"
import icon from "../images/icon-minters.webp"
import logo from "../images/logo-text-minters.svg"
import { GamesLayoutProps } from "./Bfb"


export const MintersLayout: GamesLayoutProps = {
    slideDescription: "Onchain battle world. A game cypherpunks play in dirty basements. Where tenageers escape to make their first money by being smart.",
    shortName: "Minters",
    name: "Minters",
    bannerImage: banner,
    bannerLogo: <img src={logo} alt="logo"/>,
    github: "https://github.com/Digital-Avatars-and-Robotics",
    overview: "Only those who uploaded their minds to digital avatars survived.In the metaverse,they found their new home. Yet the mystery of the past remains unsolved. Before the cataclysm the merchant called Jake foresaw the catastrophe. He created the artifacts capable of recording stories, events, and memories of people using them. Every block of information, forever stored in these devices.He then went on to propagate artifacts in the Minters society in the form of games. Why? Nobody knows exactly. Many believe artifacts hold the answer to the origins of the cataclysm.",
    description: `Enter Droetopia, a land where war is brewing between the rival kingdoms of Spooncity and Forktown. Your mission is to lead your forces, set up your best strategies, and conquer enemy strongholds. As you dive into the game, you'll find a world filled with exciting tales, secrets, and age-old myths. It's your opportunity to leave your mark and become a legend in Droetopia's unfolding story.

At the heart of Battle for Blockchain (BFB) lies a dynamic and adaptable engagement system. This game is crafted to cater to your unique playstyle, allowing you to set the pace and rhythm of your gameplay. Whether you're a strategist at heart or thrive in the heat of the moment, BFB's gameplay mechanics are flexible enough to accommodate your approach.`,
    icon: icon,
    tags: ["on-chain", "auto battler", "mmorpg", "simulation"],
    network: "Arbitrum Games",
    status: "In Development",
    platformLink: "https://papryk.com/",
}
