import "./css/CategorySelector.css";
import getBlogCategories from "../functions/getBlogCategories";
import { useState } from "react";
import pointerDown from "../images/pointer_down.svg";

type CategorySelectorProps = {
    OnChange?: (category: string) => void;
}

export default function CategorySelector(props: CategorySelectorProps) {
    const categories = getBlogCategories();
    const [category, setCategory] = useState(categories[0]);
    const [open, setOpen] = useState(false);

    const handleChange = (value: string) => {
        setCategory(value);
        setOpen(false);
        if (props.OnChange) {
            props.OnChange(value);
        }
    }

    return (
        <div className="category-selector-container">
            <div className="category-selector-title">Category</div>
            <div className="category-selector">
                <button
                    className="category-selector-selected"
                    onClick={() => setOpen(!open)}
                >[ {category.toUpperCase()} <img src={pointerDown} alt="pointer down" /> ]</button>
                {open &&
                    <div className="category-selector-options">
                        {categories.map((c, i) => {
                            return (
                                <button
                                    key={i}
                                    className="category-selector-option"
                                    onClick={() => handleChange(c)}
                                >{c}</button>
                            )
                        })}
                    </div>
                }

            </div>
        </div>
    )
}
