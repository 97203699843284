import { HashLink } from "react-router-hash-link";
import Section from "../Components/Section";
import Subsection from "../Components/Subsection";
import legal from "../Data/Legal";
import "./css/Legal.css"

type LegalRefProps = {
    children: string,
    to: string
}

function LegalRef(props: LegalRefProps) {
    const name = props.children.padEnd(15)

    return (
        <HashLink to={`/legal#${props.to}`} className="legal-ref">
            {name}
        </HashLink>
    )
}

export default function Legal() {

    return (
        <Section className="legal" title="Legal" >
            <div className="legal-content">
                <div className="legal-content-index">
                    {
                        legal.map((section, i) => (
                            <LegalRef
                                key={i}
                                to={section.title}>
                                {section.title}
                            </LegalRef>
                        ))
                    }
                </div>
                <hr className="horizontal solid" />
                <div className="legal-description">
                    {
                        legal.map((section, i) => (
                            <Subsection
                                key={i}
                                id={section.title}
                                title={section.title}>
                                {section.content}
                            </Subsection>
                        ))
                    }
                </div>
                <hr className="horizontal solid" />
            </div>
        </Section>
    )
}
