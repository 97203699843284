import "./css/NotFound.css";
import snoodle from "../images/snoodle.webp";

export default function NotFound() {
    return (
        <div className="not-found">
            <img src={snoodle} alt="404" />
            Oops! Page not found<br/>
            Error 404
        </div>
    )
}
