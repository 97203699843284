import { ReactNode } from "react"
import banner from "../images/bg-bfb.webp"
import icon from "../images/icon-bfb.webp"
import logo from "../images/logo-bfb.svg"

export type GamesLayoutProps = {
    slideDescription: string,
    shortName: string,
    name: string,
    bannerImage: string,
    bannerLogo: ReactNode,
    playUrl?: string,
    github?: string,
    overview: string,
    description: string,
    icon: string,
    tags: string[],
    network: string,
    status: string,
    platformLink: string,
}

export const BfbLayout: GamesLayoutProps = {
    shortName: "Bfb",
    name: "Battle for BlockChain",
    slideDescription: "Onchain battle world. A game cypherpunks play in dirty basements. Where tenageers escape to make their first money by being smart.",
    bannerImage: banner,
    bannerLogo: <img src={logo} alt="logo" loading="lazy"/>,
    playUrl: "https://bfb.world/",
    github: "https://github.com/Digital-Avatars-and-Robotics",
    overview: "Battle for Blockchain (BFB) is an innovative MMO Auto Battler that marks a significant leap in on-chain gaming. Developed by Mintersworld, BFB is a simulation platform for game-like autonomous warfare, powered by socio-economic incentives and integrated with crypto networks. It's a multiplayer experience set in the heart of Droetopia, where every move can alter the fate of kingdoms.",
    description: `Enter Droetopia, a land where war is brewing between the rival kingdoms of Spooncity and Forktown. Your mission is to lead your forces, set up your best strategies, and conquer enemy strongholds. As you dive into the game, you'll find a world filled with exciting tales, secrets, and age-old myths. It's your opportunity to leave your mark and become a legend in Droetopia's unfolding story.

At the heart of Battle for Blockchain (BFB) lies a dynamic and adaptable engagement system. This game is crafted to cater to your unique playstyle, allowing you to set the pace and rhythm of your gameplay. Whether you're a strategist at heart or thrive in the heat of the moment, BFB's gameplay mechanics are flexible enough to accommodate your approach.`,
    icon: icon,
    tags: ["on-chain", "auto battler", "mmorpg", "simulation"],
    network: "Arbitrum Games",
    status: "In Development",
    platformLink: "https://papryk.com/",
}
