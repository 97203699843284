import { ReactNode } from "react";
import "./css/Subsection.css";

type SubsectionProps = {
    title: string,
    id?: string,
    children?: ReactNode
}

export default function Subsection(props: SubsectionProps) {
    return (
        <>
            <h5 className="about-subsection-title" id={`${props.id ? props.id : ""}`} > {props.title} </h5>
            <hr className="solid" />
            <p>
                {props.children}
            </p>
        </>
    )
}
