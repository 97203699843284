import "./css/Section.css";
import dots from "../images/dots.svg";
import { ReactNode } from "react";

type SectionProps = {
    title: string,
    children?: ReactNode,
    className?: string,
}

export default function Section(props: SectionProps) {
    return (
        <div className={`section-header ${props.className ? props.className : ""}`}>
            <div className="section-header-content">
                <h2> {props.title} </h2>
                <img src={dots} alt="dots" />
            </div>

            <hr className="solid" />

            {props.children}
        </div>
    )
}
