import { Article } from "../global"

export const tmpArticle: Article = {
    id: 1,
    title: "How Blockchain is Changing Video Games for Privacy Fans",
    banner: "https://resources.mintersworld.com/bfb_loading_screen.webp",
    tags: ["Game Development"],
    sections: [{
        title: "The Essence of Blockchain in Gaming",
        content: `To understand how blockchain is revolutionizing video games, 
        it's crucial to grasp its fundamental principles. Blockchain technology 
        operates on a decentralized network of computers, ensuring that data is 
        not stored in a single, vulnerable location. Each transaction is 
        recorded on a public ledger, making it transparent and immutable. 
            This transparency and security are precisely what privacy advocates 
        have been clamoring for.`
    }, {
        title: "Enhanced Privacy and Security",
        content: `Traditional online games typically rely on centralized 
        servers, which are susceptible to hacking, data breaches, and 
        surveillance. For privacy fans, this centralization poses a significant 
        risk.Blockchain, however, decentralizes the storage of game data, 
        significantly reducing these vulnerabilities.Each player's data is 
        encrypted and distributed across the network, making unauthorized access 
        virtually impossible. Moreover, blockchain's inherent security features 
        ensure that in-game transactions are conducted with high levels of 
        privacy. Players can engage in trades, purchases, and other interactions 
        without exposing their personal information, thanks to cryptographic 
        techniques that anonymize user identities.`
    }, {
        title: "Enhanced Privacy and Security",
        content: `Traditional online games typically rely on centralized 
        servers, which are susceptible to hacking, data breaches, and 
        surveillance. For privacy fans, this centralization poses a significant 
        risk.Blockchain, however, decentralizes the storage of game data, 
        significantly reducing these vulnerabilities.Each player's data is 
        encrypted and distributed across the network, making unauthorized access 
        virtually impossible. Moreover, blockchain's inherent security features 
        ensure that in-game transactions are conducted with high levels of 
        privacy. Players can engage in trades, purchases, and other interactions 
        without exposing their personal information, thanks to cryptographic 
        techniques that anonymize user identities.`
    }, {
        title: "Enhanced Privacy and Security",
        content: `Traditional online games typically rely on centralized 
        servers, which are susceptible to hacking, data breaches, and 
        surveillance. For privacy fans, this centralization poses a significant 
        risk.Blockchain, however, decentralizes the storage of game data, 
        significantly reducing these vulnerabilities.Each player's data is 
        encrypted and distributed across the network, making unauthorized access 
        virtually impossible. Moreover, blockchain's inherent security features 
        ensure that in-game transactions are conducted with high levels of 
        privacy. Players can engage in trades, purchases, and other interactions 
        without exposing their personal information, thanks to cryptographic 
        techniques that anonymize user identities.`
    }, {
        title: "Enhanced Privacy and Security",
        content: `Traditional online games typically rely on centralized 
        servers, which are susceptible to hacking, data breaches, and 
        surveillance. For privacy fans, this centralization poses a significant 
        risk.Blockchain, however, decentralizes the storage of game data, 
        significantly reducing these vulnerabilities.Each player's data is 
        encrypted and distributed across the network, making unauthorized access 
        virtually impossible. Moreover, blockchain's inherent security features 
        ensure that in-game transactions are conducted with high levels of 
        privacy. Players can engage in trades, purchases, and other interactions 
        without exposing their personal information, thanks to cryptographic 
        techniques that anonymize user identities.`
    }, {
        title: "Enhanced Privacy and Security",
        content: `Traditional online games typically rely on centralized 
        servers, which are susceptible to hacking, data breaches, and 
        surveillance. For privacy fans, this centralization poses a significant 
        risk.Blockchain, however, decentralizes the storage of game data, 
        significantly reducing these vulnerabilities.Each player's data is 
        encrypted and distributed across the network, making unauthorized access 
        virtually impossible. Moreover, blockchain's inherent security features 
        ensure that in-game transactions are conducted with high levels of 
        privacy. Players can engage in trades, purchases, and other interactions 
        without exposing their personal information, thanks to cryptographic 
        techniques that anonymize user identities.`
    }]
}

export default function getArticles(): Article[] {
    return [tmpArticle, tmpArticle, tmpArticle, tmpArticle, tmpArticle, tmpArticle, tmpArticle, tmpArticle, tmpArticle, tmpArticle, tmpArticle]
}

export function getArticle(id: number): Article | null {
    return tmpArticle
}
