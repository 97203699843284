import { ReactNode } from "react";
import ContactForm from "../Components/ContactForm";
import Section from "../Components/Section";
import about from "../Data/About";
import "./css/About.css"

type SubsectionProps = {
    title: string,
    children?: ReactNode
}

function Subsection(props: SubsectionProps) {
    return (
        <>
            <h5 className="about-subsection-title" > {props.title} </h5>
            <hr className="solid" />
            <p>
                {props.children}
            </p>
        </>
    )
}

export default function About() {
    return (
        <Section className="about" title="About Minters" >
            <div className="about-content">
                <div className="about-content-mission">
                    <h4>Minters' Mission for Cypherpunks</h4>
                    <p>
                        At Minters, we are pioneering the future of gaming by
                        harnessing the power of blockchain technology. Our
                        mission is encapsulated in our guiding statement:
                        "We make onchain games for cypherpunks."
                    </p>
                    <p>
                        This succinctly defines our commitment to developing
                        gaming experiences that resonate with individuals deeply
                        embedded in the principles of cryptography, privacy,
                        and decentralization.
                    </p>
                </div>
                <hr className="horizontal solid" />
                <div className="about-description">
                    {
                        about.map((section, i) => (
                            <Subsection key={i} title={section.title}>
                                {section.content}
                            </Subsection>
                        ))
                    }
                </div>
                <hr className="horizontal solid" />
                <ContactForm />
            </div>
        </Section>
    )
}
