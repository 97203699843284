import "./css/ContactForm.css"
import dots from "../images/dots-form.svg"
import { ChangeEvent, FormEvent, useState } from "react";
import emailjs from "@emailjs/browser";


export default function ContactForm() {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
    })

    const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        console.log('Form data:', formData);
        try {
            const result = await emailjs.send(
                "service_idhedst",
                "template_3owuzck",
                formData,
                "BKxeUXAC-WiCg-qoU"
            );
            setFormData({ name: '', email: '', message: '' })
        } catch (error) {
            console.error('Failed to send email. Error:', error);
        }
    };

    return (
        <div className="contactus">
            <h4>Contact Us</h4>
            <p>
                Join us at Minters as we build the future of onchain gaming.
                Game on, and game freely.
            </p>

            <form onSubmit={handleSubmit}>
                <img src={dots} className="form-dots" alt="dots" />
                <input
                    name="name"
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleInputChange}
                />
                <input
                    name="email"
                    placeholder="Your email"
                    value={formData.email}
                    onChange={handleInputChange}
                />
                <textarea
                    name="message"
                    placeholder="Write to Us"
                    value={formData.message}
                    onChange={handleInputChange}
                />
                <button type="submit">SEND</button>
            </form>

        </div>
    )
}
