import { Children, isValidElement, ReactNode } from "react"
import { Link } from "react-router-dom"
import "./css/GameTile.css"

type GameTileProps = {
    backgroundImage: string,
    children?: ReactNode,
    to: string,
}

type GameTileDescriptionProps = {
    children: ReactNode
}

type GameTileLogoProps = {
    children: ReactNode
}

export function GameTileDescription(props: GameTileDescriptionProps) {
    return (
        <div className="gametile-description">
            {props.children}
        </div>
    )
}

export function GameTileLogo(props: GameTileLogoProps) {
    return (
        <div className="gametile-logo">
            {props.children}
        </div>
    )
}

export default function GameTile(props: GameTileProps) {
    let logo: ReactNode = null;
    let description: ReactNode = null;

    Children.forEach(props.children, (child) => {
        if (isValidElement(child)) {
            if (child.type === GameTileLogo) {
                logo = child;
            } else if (child.type === GameTileDescription) {
                description = child;
            }
        }
    });


    return (
        <div style={{ backgroundImage: `url(${props.backgroundImage})` }} className="gametile">
            <div className="gametile-gradient">
                <div className="gametile-info">
                    {logo}
                    {description}
                    <Link to={props.to} className="gametile-button">See More</Link>
                </div>
            </div>
        </div>
    )

}
