import "./css/Games.css"
import GameTile, { GameTileDescription, GameTileLogo } from "../Components/GameTile";
import rhascau from "../images/bg-rhascau.webp"
import bfb from "../images/bg-bfb.webp"
import minters from "../images/bg-minters.webp"
import logoBfb from "../images/logo-bfb.svg"
import logoRhascau from "../images/logo-rhascau.svg"
import logoMinters from "../images/logo-text-minters.svg"

export default function Games() {

    return (
        <div className="games">
            <GameTile to="/games/bfb" backgroundImage={bfb}>
                <GameTileLogo>
                    <img src={logoBfb} alt="bfb-logo"/>
                </GameTileLogo>
                <GameTileDescription>
                    FIGHT ON THE ENDLESS BATTLEFIELD
                </GameTileDescription>
            </GameTile>

            <GameTile to="/games/rhascau" backgroundImage={rhascau}>
                <GameTileLogo>
                    <img src={logoRhascau} alt="rhascau-logo"/>
                </GameTileLogo>
                <GameTileDescription>
                    BURN YOUR ENGINES ON-CHAIN STRATEGY RACING
                </GameTileDescription>
            </GameTile>

            <GameTile to="/games/minters" backgroundImage={minters}>
                <GameTileLogo>
                    <img src={logoMinters} alt="minters-logo"/>
                </GameTileLogo>
                <GameTileDescription>
                    MULTIPLAYER GAMES
                </GameTileDescription>
            </GameTile>
        </div>
    )
}
