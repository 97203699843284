import "./css/Home.css"
import BlogCarusel from "../Components/BlogCarusel";
import GameShow from "../Components/GameShow";
import Section from "../Components/Section";
import Team from "../Components/Team";

export default function Home() {
    return (
        <main className="home">
            <GameShow />
            <Section className="home-description" title="behind the code" >
                <p>
                    In our digital age, the concept of gaming has transcended
                    traditional boundaries, entering the realm of blockchain
                    technology. "We make onchain games for cypherpunks"
                    encapsulates our core mission and identity. At its essence,
                    this statement signifies our commitment to crafting gaming
                    experiences that resonate with individuals deeply entrenched in
                    the ethos of cryptography, privacy, and decentralization.
                </p>
                <p>
                    The term "onchain games" refers to a burgeoning category of
                    video games built on blockchain technology. Unlike
                    conventional games, which are typically hosted on
                    centralized servers, onchain games leverage the immutable
                    and transparent nature of blockchain to offer players
                    unprecedented ownership and control over in-game assets.
                    By integrating blockchain protocols into game mechanics,
                    we empower players to truly own their digital belongings,
                    fostering a new era of player-driven economies and
                    experiences.
                </p>
                <p>
                    Central to our philosophy is the concept of "cypherpunks."
                    Coined in the late 1980s, cypherpunks are individuals who
                    advocate for the widespread use of strong cryptography and
                    privacy-enhancing technologies to safeguard personal
                    freedoms and civil liberties. They champion the principles
                    of decentralization, encryption, and anonymity as tools for
                    empowering individuals in the digital realm. By targeting
                    our games towards cypherpunks, we align ourselves with a
                    community that values privacy, security, and
                    self-sovereignty in all facets of their digital interactions.
                </p>
            </Section>
            <Section title="blog" >
                <BlogCarusel />
            </Section>
            <Section title="team" >
                <Team />
            </Section>
        </main>
    )
}
