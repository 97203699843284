import "./css/GameShow.css"
import { BfbLayout } from "../Data/Bfb"
import { MintersLayout } from "../Data/Minters"
import { RhascauLayout } from "../Data/Rhascau"
import { useState } from "react";
import GameInfoPanel from "./GameInfoPanel";
import { Link } from "react-router-dom";

const games = [BfbLayout, MintersLayout, RhascauLayout];


export default function GameShow() {
    const [slide, setSlide] = useState(BfbLayout);

    return (
        <div className="game-show">
            <div className="gs-head">
                <h2 className="gs-title">
                    We make <mark className="orange">onchain games</mark> for cypherpunks
                </h2>
                <div className="gs-selector-list">
                    {
                        games.map((props, id) =>
                            <div key={id}>
                                <button onClick={() => setSlide(games[id])} className={`gs-selector-button ${slide === games[id] ? "active" : ""}`}>
                                    <div className="gs-selector-frame" />
                                    <img className="gs-selector-icon" src={props.icon} alt="selector icon"></img>
                                </button>
                            </div>
                        )
                    }
                </div>
            </div>

            <div style={{ backgroundImage: `url(${slide.bannerImage})` }} className="gs-slide">
                <div className="gs-slide-overlay" >
                    <div className="gs-slide-name">{slide.name}</div>
                    <p className="gs-slide-description">{slide.slideDescription}</p>
                    <GameInfoPanel
                        tags={slide.tags}
                        network={slide.network}
                        status={slide.status}
                        platformLink={slide.platformLink}
                    />
                    <Link className="gs-slide-button" to="/games/bfb">View Game</Link>
                </div>
            </div>
        </div>
    )
}
