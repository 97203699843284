import "./css/ArticlePage.css";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getArticle, tmpArticle } from "../functions/getArticles";
import { Article } from "../global";

type ArticlePageProps = {
    article?: Article
}

export default function ArticlePage(props: ArticlePageProps) {
    const { artId } = useParams();
    const [article, setArticle] = useState<Article>(tmpArticle);

    useEffect(() => {
        if (artId === undefined) {
            return;
        }
        const id = parseInt(artId);
        const art = getArticle(id)
        if (!art) {
            setArticle(tmpArticle)
        }
    }, [artId])

    if (props.article) {
        return (
            <div className="art-page">
                <div className="art-spacer"></div>
                <div className="art-container">
                    <img src={props.article.banner} alt="banner" className="art-banner" />
                    <div className="art-title">
                        <div className="gip-tag">{props.article.tags[0]}</div>
                        {props.article.title}
                    </div>
                    <div className="art-content">
                        {
                            props.article.sections.map((section, i) => (
                                <div key={i} className="art-section">
                                    <div className="art-section-title">
                                        {section.title}
                                    </div>
                                    <div className="art-section-content">
                                        {section.content}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="art-spacer"></div>
            </div>
        )
    }
    return (
        <div className="art-page">
            <div className="art-spacer"></div>
            <div className="art-container">
                <img src={article.banner} alt="banner" className="art-banner" />
                <div className="art-title">
                    <div className="gip-tag">{article.tags[0]}</div>
                    {article.title}
                </div>
                <div className="art-content">
                    {
                        article.sections.map((section, i) => (
                            <div key={i} className="art-section">
                                <div className="art-section-title">
                                    {section.title}
                                </div>
                                <div className="art-section-content">
                                    {section.content}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="art-spacer"></div>
        </div>
    )
}
