import { Link, useLocation } from "react-router-dom";
import "./css/Header.css";
import logoX from "../images/x.svg";
import logoGithub from "../images/github.svg";
import logoDiscord from "../images/discord.svg";
import logoMinters from "../images/logo-minters.svg"
import list from "../images/list.svg";
import listX from "../images/list-x.svg";
import { useEffect, useState } from "react";

export default function Header() {
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [mobile, setMobile] = useState(false)

    useEffect(() => {
        const { innerWidth: width, innerHeight: height } = window;
        if (width <= 800) setMobile(true);
        window.matchMedia('(max-width: 800px)')
            .addEventListener('change', (e) => setMobile(e.matches))
    }, []);

    return (
        <header>
            <div className="header-minters" >
                <button className="header-list-button" onClick={() => setOpen(!open)}>
                    <img src={list} alt="list" />
                    <img src={listX} alt="list-x" style={{ visibility: open || !mobile ? "visible" : "hidden" }} />
                </button>
                <img className="header-logo" src={logoMinters} alt="logo" />
                <div className="spacer-horizontal" />
            </div>
            <hr className="solid" />
            <div className="navbar" style={{ visibility: open || !mobile ? "visible" : "hidden" }}>
                <nav className="header-links" >
                    <Link
                        to="/"
                        onClick={() => setOpen(!mobile)}
                        className={`header-button ${location.pathname === "/" ? "active" : ""}`}>
                        home<div className="header-media-spacer" />
                    </Link>
                    <Link
                        to="/games"
                        onClick={() => setOpen(!mobile)}
                        className={`header-button ${location.pathname === "/games" ? "active" : ""}`}>
                        games<div className="header-media-spacer" />
                    </Link>
                    <Link
                        to="/blog"
                        onClick={() => setOpen(!mobile)}
                        className={`header-button ${location.pathname === "/blog" ? "active" : ""}`}>
                        blog<div className="header-media-spacer" />
                    </Link>
                    <Link
                        to="/about"
                        onClick={() => setOpen(!mobile)}
                        className={`header-button ${location.pathname === "/about" ? "active" : ""}`}>
                        about<div className="header-media-spacer" />
                    </Link>
                    <Link
                        to="/legal"
                        onClick={() => setOpen(!mobile)}
                        className={`header-button ${location.pathname === "/legal" ? "active" : ""}`}>
                        legal<div className="header-media-spacer" />
                    </Link>
                </nav>
                <div className="header-spacer-horizontal" />
                <div className="header-socials" >
                    <a href="https://x.com/mintersworld">
                        <img width={30} height={30} src={logoX} alt="X" />
                    </a>
                    <a href="https://github.com/Digital-Avatars-and-Robotics">
                        <img width={30} height={30} src={logoGithub} alt="Github" />
                    </a>
                    <a href="https://discord.com/invite/minters">
                        <img width={30} height={30} src={logoDiscord} alt="Discord" />
                    </a>

                </div>
            </div>
        </header>
    )
}
