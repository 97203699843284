import { useState } from "react";
import { User } from "../Pages/AdminPanel";
import "./css/LoginPanel.css";

type LoginPanelProps = {
    setUser: (user: User) => void;
}

export default function LoginPanel(props: LoginPanelProps) {
    const [state, setState] = useState<User & { password: string }>({
        username: "",
        password: "",
        loggedIn: false
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        });

    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.setUser({
            username: state.username,
            jwt: "hehe",
            loggedIn: true
        });


        // get jwt token
        /*
        fetch("http://localhost:3001/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                username: state.username,
                password: state.password
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                props.setUser({
                    username: state.username,
                    jwt: data.jwt,
                    loggedIn: true
                });
            })
            .catch(err => console.log(err));
        */
    }

    return (
        <div className="login-panel">
            <h2 className="login-panel-form-title">Login</h2>
            <form
                onSubmit={handleSubmit}
                className="login-panel-form">
                <input
                    type="text"
                    placeholder="Username"
                    name="username"
                    className="login-panel-form-username"
                    value={state.username}
                    onChange={handleInputChange} />
                <input
                    type="password"
                    placeholder="Password"
                    name="password"
                    className="login-panel-form-passwd"
                    value={state.password}
                    onChange={handleInputChange} />
                <button
                    type="submit"
                    className="login-panel-submit">Login</button>
            </form>
        </div>
    )
}
