import "./css/Footer.css";

export default function Footer() {
    return (
        <footer>
            <hr className="solid" />
            <div className="footer-rights">
                Minters 2024 all right reserved
            </div>
        </footer>
    )
}
