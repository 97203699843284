import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { BfbLayout } from './Data/Bfb';
import { MintersLayout } from './Data/Minters';
import { RhascauLayout } from './Data/Rhascau';
import './index.css';
import About from './Pages/About';
import AdminPanel from './Pages/AdminPanel';
import ArticlePage from './Pages/ArticlePage';
import Blog from './Pages/Blog';
import Games from './Pages/Games';
import GamesLayoutWrapper from './Pages/GamesLayout';
import Home from './Pages/Home';
import Layout from './Pages/Layout';
import Legal from './Pages/Legal';
import NotFound from './Pages/NotFound';

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="games" element={<Games />} />
                    <Route path="games/bfb"  element={<GamesLayoutWrapper layoutProps={BfbLayout}/>} />
                    <Route path="games/rhascau" element={<GamesLayoutWrapper layoutProps={RhascauLayout} />} />
                    <Route path="games/minters" element={<GamesLayoutWrapper layoutProps={MintersLayout} />} />
                    <Route path="blog" element={<Blog />} />
                    <Route path="article/:artId" element={<ArticlePage />} />
                    <Route path="about" element={<About />} />
                    <Route path="legal" element={<Legal />} />
                    <Route path="adminpanel" element={<AdminPanel />} />
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </BrowserRouter >
    );
}


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
