export default function getBlogCategories() {
    return [
        "all",
        "game development",
        "infrastructure",
        "newsletter",
        "insights",
        "web3",
    ]
}
