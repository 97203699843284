import { Link } from "react-router-dom"
import "./css/GameLayout.css"
import arrow from "../images/arrow.svg"
import github from "../images/github.svg"
import GameInfoPanel from "../Components/GameInfoPanel"
import { GamesLayoutProps } from "../Data/Bfb"

export type GamesLayoutWrapperProps = {
    layoutProps: GamesLayoutProps
}

export function GamesLayout(props: GamesLayoutProps) {
    return (
        <div className="game-layout">
            <Link className="gl-back-button" to="/games"><img src={arrow} alt="arrow" /> Back</Link>
            <div style={{ backgroundImage: `url(${props.bannerImage})` }} className="gl-banner">
                <div className="gl-banner-side" />
                <div className="gl-banner-logo">
                    {props.bannerLogo}
                </div>
                <div className="gl-banner-side">
                    {
                        props.github ?
                            <a href={props.github} className="gl-github">
                                <img src={github} alt="github" />
                                <div> View on Github</div>
                            </a>
                            : ""
                    }
                    {
                        props.playUrl ?
                            <a href={props.playUrl} className="gl-play">
                                Play
                            </a>
                            : ""
                    }
                </div>
            </div>
            <div className="gl-info">
                <div className="gl-description">
                    <div className="gl-overview">
                        <h3 className="gl-description-head">
                            Overview
                        </h3>
                        <div className="gl-overview-content">
                            {props.overview}
                        </div>
                    </div>
                    <div className="gl-description">
                        <h3 className="gl-description-head">
                            The World of Droetopia
                        </h3>
                        <div className="gl-description-content">
                            {props.description}
                        </div>
                    </div>
                </div>
                <GameInfoPanel
                    icon={props.icon}
                    tags={props.tags}
                    network={props.network}
                    status={props.status}
                    platformLink={props.platformLink}
                />
            </div>
        </div>
    )
}

export default function GamesLayoutWrapper({ layoutProps }: GamesLayoutWrapperProps) {
    return <GamesLayout {...layoutProps} />;
}
