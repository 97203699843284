import "./css/Team.css";
import logoX from "../images/x-team.svg"

type MemberProps = {
    name: string,
    desciption: string,
    xlink?: string
}

function Member(props: MemberProps) {
    return (
        <div className="member">
            <div className="square-bracket-left" />
            <div className="member-content">
                <div className="member-name">
                    {props.name}
                </div>
                <div className="member-description">
                    {props.desciption}
                </div>
                {props.xlink ?
                    <a href={props.xlink} className="member-x" style={{ visibility: props.xlink ? "visible" : "hidden" }}>
                        <img className="member-x-img" src={logoX} alt="X" style={{ visibility: props.xlink ? "visible" : "hidden" }} />
                    </a>
                    :
                    <div className="member-x" />
                }
            </div>
            <div className="square-bracket-right" />
        </div>
    )
}

export default function Team() {
    return (
        <div className="team">
            <div className="team-row">
                <Member name="Stokarz" desciption="FOUNDER & CEO" xlink="http" />
                <Member name="Grzyslaw" desciption="FULLSTACK DEVELOPER" />
                <Member name="Farathon" desciption="GAME DESIGNER" />
            </div>
            <div className="team-row">
                <Member name="Ghinter" desciption="FULLSTACK DEVELOPER" />
                <Member name="Marcineq" desciption="FULLSTACK DEVELOPER" />
                <Member name="Papryk" desciption="FULLSTACK DEVELOPER" />
            </div>
        </div>
    )
}
