import "./css/GameInfoPanel.css"
export type GamesInfoPanelProps = {
    icon?: string,
    tags: string[],
    network: string,
    status: string,
    platformLink: string,
}

export default function GameInfoPanel(props: GamesInfoPanelProps) {
    return (
        <div className="gip-panel">
            {props.icon ?
                <img src={props.icon} alt="icon" />
                : ""
            }
            <div className="gip-tags">
                {
                    props.tags.map((tag, i) => <div key={i} className="gip-tag">{tag}</div>)
                }
            </div>
            <div className="gip-panel-info-list">
                <div className="gip-panel-info">
                    <div className="gip-panel-info-name">
                        Network
                    </div>
                    <div className="gip-dots" />
                    <div className="gip-panel-info-value">
                        {props.network}
                    </div>
                </div>
                <div className="gip-panel-info">
                    <div className="gip-panel-info-name">
                        Status
                    </div>
                    <div className="gip-dots" />
                    <div className="gip-panel-info-value">
                        {props.status}
                    </div>
                </div>
                <div className="gip-panel-info">
                    <div className="gip-panel-info-name">
                        Platforms
                    </div>
                    <div className="gip-dots" />
                    <div className="gip-panel-info-value">
                        <a className="gip-panel-platform-link" href={props.platformLink}>{props.platformLink}</a>
                    </div>
                </div>
            </div>
        </div>
    )
}
