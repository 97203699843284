const about = [
    {
        title: "Our vision",
        content: `We specialize in "onchain games," a burgeoning category of 
        video games built on blockchain technology. Unlike conventional games 
        hosted on centralized servers, onchain games leverage the immutable and 
        transparent nature of blockchain to offer players unprecedented 
        ownership and control over in-game assets. By integrating blockchain 
        protocols into game mechanics, we empower players to truly own their 
        digital belongings, fostering a new era of player-driven economies and 
        experiences.`
    },
    {
        title: "What we do",
        content: `We specialize in developing onchain games—a cutting-edge 
        category of video games built on blockchain technology. Unlike 
        conventional games hosted on centralized servers, our games leverage the 
        immutable and transparent nature of blockchain. This integration allows 
        players to gain unprecedented ownership and control over in-game assets. 
        By embedding blockchain protocols into our game mechanics, we empower 
        players to truly own their digital belongings, fostering a new era of 
        player-driven economies and experiences.`
    },
    {
        title: "Our commitment",
        content: `We are committed to pioneering the future of gaming by merging 
        the exciting possibilities of blockchain with the timeless appeal of 
        immersive gameplay. Join us as we build a gaming ecosystem where players 
        are truly in control, where their assets are secure, and where the 
        principles of decentralization and privacy are woven into the fabric of 
        every game we create. Welcome to the future of gaming. Welcome to our 
        community.`
    },
    {
        title: "Our community",
        content: `Central to our philosophy is the concept of "cypherpunks." 
        Coined in the late 1980s, cypherpunks are individuals who advocate for 
        the widespread use of strong cryptography and privacy-enhancing 
        technologies to safeguard personal freedoms and civil liberties. They 
        champion the principles of decentralization, encryption, and anonymity 
        as tools for empowering individuals in the digital realm. By targeting 
        our games towards cypherpunks, we align ourselves with a community that 
        values privacy, security, and self-sovereignty in all facets of their 
        digital interactions.`
    },
    {
        title: "Join Us",
        content: `Central to our philosophy is the concept of "cypherpunks." 
        Coined in the late 1980s, cypherpunks are individuals who advocate for 
        the widespread use of strong cryptography and privacy-enhancing 
        technologies to safeguard personal freedoms and civil liberties. They 
        champion the principles of decentralization, encryption, and anonymity 
        as tools for empowering individuals in the digital realm. By targeting 
        our games towards cypherpunks, we align ourselves with a community that 
        values privacy, security, and self-sovereignty in all facets of their 
        digital interactions.`
    },
]

export default about;
