import "./css/AdminPanel.css";
import LoginPanel from "../Components/LoginPanel";
import { ChangeEvent, useState } from "react";
import { Link } from "react-router-dom";
import { Article } from "../global";
import ArticlePage from "./ArticlePage";

export type User = {
    username: string,
    jwt?: string,
    loggedIn: boolean
}

export type MenuPanelProps = {
    user: User
};

function MenuPanel(props: MenuPanelProps) {
    return (
        <div className="menu-panel">
            <h2>Hello, {props.user.username}</h2>
            <Link to="/createarticle">
                <div>Add article</div>
            </Link>
        </div>
    )
}


function AddArticle() {
    const [article, setArticle] = useState<Article>({
        id: 0,
        title: "Title",
        banner: "https://via.placeholder.com/150",
        tags: ["develompent"],
        sections: [{
            title: "Section Title",
            content: "Section Content"
        }]
    })

    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        console.log(article)
        setArticle({
            ...article,
            [e.target.name]: e.target.value
        });
    }

    const addSection = () => {
        setArticle({
            ...article,
            sections: [...article.sections, {
                title: "",
                content: ""
            }]
        })
    }

    return (
        <div className="add-article">
            <div className="add-art-input-panel">
                <input
                    type="text"
                    onChange={onChange}
                    value={article.title}
                    name="title"
                    placeholder="Title" />
                <input
                    type="text"
                    onChange={onChange}
                    value={article.banner}
                    name="banner"
                    placeholder="Banner" />
                <input
                    type="text"
                    onChange={onChange}
                    value={article.tags}
                    name="tags"
                    placeholder="Tags" />
                {
                    article.sections.map((section, index) => {
                        return (
                            <div className="art-add-section" key={index}>
                                <input
                                    type="text"
                                    onChange={onChange}
                                    name="section-title"
                                    value={section.title}
                                    key={index}
                                    placeholder="Section Title" />
                                <textarea
                                    onChange={onChange}
                                    name="section-content"
                                    value={section.content}
                                    key={index}
                                    placeholder="Section Content" />
                            </div>
                        )
                    })
                }
                <button onClick={addSection}>Add Section</button>
                <button type="submit">Add Article</button>
            </div>
            <div className="add-art-preview">
            <ArticlePage article={article} />
            </div>
        </div>
    )
}

export default function AdminPanel() {
    const [user, setUser] = useState<User>({
        username: "",
        loggedIn: false
    });

    return (
        <div className="">
            {user.loggedIn ?
                <AddArticle /> :
                <LoginPanel setUser={setUser} />
            }
        </div>
    )
}
