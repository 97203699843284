import banner from "../images/bg-rhascau.webp"
import icon from "../images/icon-rhascau.webp"
import logo from "../images/logo-rhascau.svg"
import { GamesLayoutProps } from "./Bfb"


export const RhascauLayout: GamesLayoutProps = {
    slideDescription: "Onchain battle world. A game cypherpunks play in dirty basements. Where tenageers escape to make their first money by being smart.",
    shortName: "Rhascau",
    name: "Rhascau",
    bannerImage: banner,
    bannerLogo: <img src={logo} alt="logo"/>,
    playUrl: "https://www.rhascau.com/",
    github: "https://github.com/Digital-Avatars-and-Robotics/rhascau",
    overview: "Go on the journey through the hottest sands of alta kei, one of the planets in the minters universe. On a remote trading outpost, the legendary avatar trader Jake runs his famous shop. Collector, Bounty Hunters and Pilots, All Gather Inside. There is only one goal. Play Rhazcau and Win precious treasures",
    description: `Enter Droetopia, a land where war is brewing between the rival kingdoms of Spooncity and Forktown. Your mission is to lead your forces, set up your best strategies, and conquer enemy strongholds. As you dive into the game, you'll find a world filled with exciting tales, secrets, and age-old myths. It's your opportunity to leave your mark and become a legend in Droetopia's unfolding story.

At the heart of Battle for Blockchain (BFB) lies a dynamic and adaptable engagement system. This game is crafted to cater to your unique playstyle, allowing you to set the pace and rhythm of your gameplay. Whether you're a strategist at heart or thrive in the heat of the moment, BFB's gameplay mechanics are flexible enough to accommodate your approach.`,
    icon: icon,
    tags: ["on-chain", "auto battler", "mmorpg", "simulation"],
    network: "Arbitrum Games",
    status: "In Development",
    platformLink: "https://papryk.com/",
}
