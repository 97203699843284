import { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom";
import getArticles from "../functions/getArticles";
import { Article } from "../global";
import "./css/BlogCarusel.css"

export type ThumbnailProps = {
    article: Article
    includeTags?: boolean
}

function Thumbnail(props: ThumbnailProps) {
    return (
        <Link to={`/article/${props.article.id}`} className="bc-thumbnail">
            <img src={props.article.banner} alt="thumbnail" className="bc-tn-banner" />
            <div className="bc-tn-title">
                {props.includeTags && props.article.tags.length >= 1 ?
                    <div className="gip-tag">{props.article.tags[0]}</div>
                    : ""
                }

                <div className="bc-tn-title-text">
                    {props.article.title}
                </div>
            </div>
        </Link>
    )
}

export default function BlogCarusel() {
    const [scroll, setScroll] = useState(0);
    const articles = getArticles();

    function moveScroll(direction: -1 | 1) {
        const list = document.getElementsByClassName('bc-thumbnail-list')[0]
        const wrapper = document.getElementsByClassName('bc-thumbnail-wrapper')[0]
        const thumbnailWidth = document.getElementsByClassName('bc-thumbnail')[0].getBoundingClientRect().width
        const gap = parseInt(window.getComputedStyle(wrapper).getPropertyValue("gap"))
        const wrapperWidth = wrapper.children.length * (gap + thumbnailWidth) - gap
        const newValue = scroll + (thumbnailWidth + gap) * direction
        console.log(newValue, wrapperWidth, thumbnailWidth, gap)
        const max = -(wrapperWidth - list.getBoundingClientRect().width)

        if (newValue > 0) {
            setScroll(0)
            return
        }
        if (newValue < max) {
            setScroll(max)
            return
        }

        setScroll(newValue)
    }

    return (
        <div className="blog-carusel">
            <button onClick={() => moveScroll(1)} className="bc-arrow left" >
                {"<"}
            </button>
            <div className="bc-thumbnail-list">
                <div style={{ left: `${scroll}px` }} className="bc-thumbnail-wrapper">
                    {
                        articles.map((article, i) => (
                            <Thumbnail key={i} article={article} />
                        ))
                    }
                </div>
            </div>
            <button onClick={() => moveScroll(-1)} className="bc-arrow right" >
                {">"}
            </button>
        </div>
    )
}
